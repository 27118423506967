<template>
  <div>
    けろよーん {{ $isLogin }}

    <!-- Login hidden component -->
    <Login
      tosUrl="https://connect.uedasoft.com/docs/tos.html"
      privacyPolicyUrl="https://connect.uedasoft.com/docs/privacy.html"
    />
  </div>
</template>

<script>
import {Login as Login} from 'vue2-faui-user-fe/login'
export default {
  components: {Login},
}
</script>