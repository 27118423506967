<template>
  <div>
    けろよーん {{ $isLogin }}

    <!-- Login hidden component -->
    <Login
      tosUrl="https://connect.uedasoft.com/docs/tos.html"
      privacyPolicyUrl="https://connect.uedasoft.com/docs/privacy.html"
    />
    <v-btn
      @click="registerOwner"
    >
      register
    </v-btn>
    <v-btn
      @click="cancelOwner"
    >
      cancel
    </v-btn>
  </div>  
</template>

<script>
import {Login as Login} from 'vue2-faui-user-fe/login'
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'
export default {
  components: {Login},
  mixins: [IdToken],
  methods: {
    registerOwner(){
      if (this.$isLogin){
        const url="https://laf-srv.uedasoft.com/registerowner"
        this.postTagidAndidToken(url)
      }
    },
    cancelOwner(){
      if (this.$isLogin){
        const url="https://laf-srv.uedasoft.com/cancelowner"
        this.postTagidAndidToken(url)
      }
    },
    async postTagidAndidToken(url){
      console.log(url)
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({"tagID":this.$route.params.id,"IDToken":await this.getIdToken()})
      })
      let err = null
      if (res.status != 200) {
        err = await res.json();
        console.log("err", err)
      }
      return err
    }
  },
}
</script>