<template>
  <div>
    {{ tag }}
    <v-card>
      <v-card-title>
        Messages
      </v-card-title>
      <v-card-text>
        <v-list>
          <Message v-for="message, i in tag.Messages" v-bind:key="i"
            :message="message"
          />
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
var sprintf = require('sprintf-js').sprintf//,
//    vsprintf = require('sprintf-js').vsprintf
import Message from '@/components/Message.vue'

export default {
  components: {
    Message
  },
  data: () => ({
      tag: {},
  }),
  async mounted() {
      const url = sprintf("https://laf-srv.uedasoft.com/gettag/%s", this.$route.params.id);
      const res = await fetch(url);
      if (res.status == 200) {
          const data = await res.json();
          console.log("tag", data);
          this.tag = data;
      }
  },
}
</script>