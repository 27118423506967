import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Tags from '../views/Tags.vue'
import Tag from '../views/Tag.vue'
import RegisterOwner from '@/views/RegisterOwner.vue'
import MessageAdd from '../views/MessageAdd.vue'
import {AccountRoutes as AccountRoutes} from 'vue2-faui-user-fe/account'

Vue.use(VueRouter)
console.log("AccountRoutes.routes", AccountRoutes.routes)

const routes = [
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags
  },
  {
    path: '/tag/:id',
    name: 'Tag',
    component: Tag
  },
  {
    path: '/registerowner/:id',
    name: 'RegisterOwner',
    component: RegisterOwner
  },
  {
    path: '/messageadd/:id',
    name: 'MessageAdd',
    component: MessageAdd
  },
  {
    path: '/document',
    name: 'Document',
    beforeEnter() {location.href = 'https://lostandfound-doc.uedasoft.com'}
  },
  // Expand into routes array
  ...AccountRoutes.routes,
  
  // should be place at the last
  {
    path: '/:id',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'About',
    component: About
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
