// for Global Mixin
// https://v2.vuejs.org/v2/guide/mixins.html?redirect=true#Global-Mixin

import Vue from 'vue'

const get_browser_lang = () => {
  // refer https://stackoverrun.com/ja/q/4348592#16010881 for checking if exists
  if (localStorage.$lang){
    return localStorage.$lang
  } else {
    const browser_lang = navigator.language.toLowerCase();
    switch(true){
      case /ja.*/.test(browser_lang):
        return "ja";
//      case /zh.*/.test(browser_lang):
//        return "zh";
      case /fr.*/.test(browser_lang):
        return "fr";
//      case /de.*/.test(browser_lang):
//        return "de"
      default:
        return "en";
    }
  }
};

export default {
  init: () => {
    let globalData = new Vue({
      data: { 
        $lang: get_browser_lang()
      }
    });
    let computed = {
      $lang: {
        get: function () { return globalData.$data.$lang },
        set: function (newLang) { globalData.$data.$lang = newLang; }
      }
    };
    return computed
  },
}