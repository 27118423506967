<template>
  <div v-if='err != ""'>
    err: {{ err }}
  </div>  
</template>

<script>
export default {
  data: () => ({
      err: "",
  }),
  mounted: async function(){
    let id = this.$route.params.id
    console.log("id", id)
    const url="https://laf-srv.uedasoft.com/gettagstatus"
    console.log(url)
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({"tagID":this.$route.params.id})
    })
    if (res.status == 200) {
      this.err = ""
      const result = await res.json();
      console.log("result", result)
      if (result.isOwned == true){
        this.$router.push({ name: 'MessageAdd', params: { id: id } })
      } else {
        this.$router.push({ name: 'RegisterOwner', params: { id: id } })
      }
      console.log("this.$isLogin",this.$isLogin)
    } else {
      const err = await res.json();
      this.err = err
      console.log("err", err)
    }
  }
}
</script>