<template>
  <div class="home">
    <Gratitude/>
    <v-card>
      <v-card-title>
        Leave a message to help me find this.
      </v-card-title>
      <v-card-text>
        <p class="text-caption">For example; place, a sign and a landmark. Thank you for your kindly help to find this.</p>
        <v-textarea
          solo
          v-model="message.MessageString"
        ></v-textarea>
      </v-card-text>
      <v-card-title>
        Provide the location data of this device.
      </v-card-title>
      <v-card-text>
        <p class="text-caption">Push "Leave" button, then the location data will be obtained and added to the message for the owner of this lost article automatically. 
          Thank you for your kindly help to find this.</p>

        <v-btn
          v-if="message.Latitude == 0 && message.Longitude == 0"
          @click="getPosition"
        >
          Leave
        </v-btn>

        <v-btn
          v-if="message.Latitude != 0 && message.Longitude != 0"
          @click="showMap"
        >
          <V-icon>location_on</V-icon>map
        </v-btn>

        <v-btn
          v-if="message.Latitude != 0 && message.Longitude != 0"
          @click="clear"
        >
          <V-icon>delete</V-icon>clear
        </v-btn>

        <p>latitude: {{ message.Latitude }}</p>
        <p>longitude: {{ message.Longitude }}</p>
        <p>accuracy: {{ accuracy }} meter radius</p>
      </v-card-text>
      <v-card-actions>
        <!-- Floating Action Button -->
        <v-btn 
          absolute
          bottom
          right
          color="primary"
          @click="send"
        >
          <V-icon>send</V-icon>send
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Gratitude from '@/components/Gratitude'
export default {
  components: {
    Gratitude
  },
  data: () => ({
    message: {
      MessageString: "",
      Latitude: 0,
      Longitude: 0
    },
    latitude: 0,
    longitude: 0,
    accuracy: 0,
  }),
  methods: {
    getPosition(){
      window.navigator.geolocation.getCurrentPosition((position)=>{
        this.message.Latitude = position.coords.latitude
        this.message.Longitude = position.coords.longitude
        this.accuracy = position.coords.accuracy
        console.log("position", position)
      });
    },
    showMap() {
      const url = "https://www.google.com/maps/place/" + this.message.Latitude + "," + this.message.Longitude
//      location.href = "https://www.google.com/maps/place/" + this.latitude + "," + this.longitude
      open(url, '_blank')
    },
    clear(){
      this.message.Latitude = 0
      this.message.Longitude = 0
      this.accuracy = 0
    },
    async send(){
      const url="https://laf-srv.uedasoft.com/addmessage/" + this.$route.params.id
      console.log(url)
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.message)
      })
      if (res.status == 200) {
        console.log("save completed")
      }
    }
  },
  computed: {
    dateTime(){
      return new Date(this.timestamp);
    }
  },
  mounted: function(){
    console.log("id", this.$route.params.id)
/*
    window.navigator.geolocation.getCurrentPosition(function(position){
      console.log("position", position)
    });    
*/
  }
}
</script>