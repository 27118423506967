import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import fbinit from 'vue2-faui-user-fe/fbinit'
import initialize from 'vue2-faui-user-fe/initialize'
import lang from '@/mixins/globals/lang'


Vue.config.productionTip = false

// firebase
fbinit.fbinit()

// lang
Vue.mixin({
  computed: lang.init()
})

// login accounts
Vue.mixin({
  computed: {
    ...initialize.accountinit(false),
  }
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
