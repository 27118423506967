<template>
  <v-app>

<!-- Navigation bar -->
    <Navbar :links="links">
      <template v-slot:title>
        <Title/>
      </template>
    </Navbar>

    <v-main>
      <router-view></router-view>
    </v-main>

  </v-app>
  </template>

<script>
import {AccountRoutes as AccountRoutes} from 'vue2-faui-user-fe/account'
//import {PurchaseRoutes as PurchaseRoutes} from 'vue2-faui-user-fe/purchase'
import {Navbar} from 'vuetify-nav2'
import Title from '@/components/Navbar/Title'

export default {
  name: 'App',
  components: { Navbar, Title},
  data: function(){
    return {

    }
  },
  computed: {
    links: function(){
      console.log("this.$isLogin", this.$isLogin)
      if (this.$isLogin) {
        return [
            { icon: 'home', text: 'Your Tags', route: '/tags'},
            { icon: 'home', text: 'Home', route: '/'},
            { icon: 'description', text: 'Document', route: '/document'},
            AccountRoutes.menuItem,
          ]
        
      } else {
        return [
            { icon: 'login', text: 'Login', route: '/tags'},
            { icon: 'home', text: 'Home', route: '/'},
            { icon: 'description', text: 'Document', route: '/document'},
//            AccountRoutes.menuItem,
          ]
      }
    }
  },
  watch: {
    $displayName: function(newVal){
      console.log("$displayName",newVal)
    }
  },
  mounted: function(){
    console.log("App.window", window)
  }
};
</script>