<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        <p class="timestamp">{{ new Date(message.Timestamp * 1000).toString() }}</p>
      </v-list-item-title>
      <span> {{ message.MessageString }}</span>
      <v-list-item-action>
        <v-btn 
          x-small 
          v-if="message.Latitude != 0 && message.Longitude != 0"
          @click="showMap"
        >
          <V-icon>location_on</V-icon>map
        </v-btn>
      </v-list-item-action>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    message: {type: Object, 'default': {}}
  },
  methods: {
    showMap() {
      const url = "https://www.google.com/maps/place/" + this.message.Latitude + "," + this.message.Longitude
//      location.href = "https://www.google.com/maps/place/" + this.latitude + "," + this.longitude
      open(url, '_blank')
    },
  }
}
</script>

<style scoped>
  .timestamp {
    font-size: 8px;
    margin: 0 0 0 2px;
  }
</style>